import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {parse, format, isSameDay, isToday, getDay} from "date-fns"
import {fr} from "date-fns/locale"
import SlideAnimation from "./SlideAnimation";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames"
import sandbox from "../../../service/Sandbox";
import AgencyHours from "./AgencyHours";

const TimePicker = ({day, mode, name, value, onBackButtonClick, onChange, withLimit = false, min, max, hourDiff = 12, agencyHours = null, agency, hoursAndMinutes = null}) => {

    const [startDate, setStartDate] = useState(null)
    const [hoursRange, setHoursRange] = useState([])
    const [minutesRange, setMinutesRange] = useState([])

    const [state, setState] = useState({
        hour: '12',
        minute: '00'
    })
    const [disabledHours, setDisabledHours] = useState([])

    const agencyAvailabilityValues = useMemo(() => {
        // agency_name, st
        if(agency && day) {
            const dayOfTheWeek = getDay(parse(day, 'yyyy-MM-dd', new Date()))
            const result = agency?.availability_periods?.filter(item => item.day === dayOfTheWeek) ?? []
            return result?.sort((item1, item2) => {
                if (item1.start_hour > item2.start_hour) {return 1}
                if (item1.start_hour < item2.start_hour) {return -1}
                return 0
            })
        }
    }, [agency, day]);

    const agencyOperatingHours = useMemo(() => {
        if(agency && day) {
            const dayOfTheWeek = getDay(parse(day, 'yyyy-MM-dd', new Date()))
            const type = mode === "start" ? "booking_start" : "booking_end"
            const result = agency?.operating_hours?.filter(item => item.day === dayOfTheWeek && item.type === type) ?? []
            return result?.sort((item1, item2) => {
                if (item1.min_hour > item2.min_hour) {return 1}
                if (item1.min_hour < item2.min_hour) {return -1}
                return 0
            })
        }
    }, [agency, day])

    const agencyName = useMemo(() => {
        if(agency) {
            if(agency?.public_name) {return agency.public_name} else {return  agency.name}
        }
        return null
    }, [agency])
    useEffect(() => {
        if(day) {
            const date = parse(day, 'yyyy-MM-dd', new Date())
            setStartDate(date)
        }
    }, [day]);

    useEffect(() => {
        if(value) {
            const split = value?.split(':')
            if(split?.length) {
                if(agencyHours?.length) {
                    const minHour = agencyHours[0]
                    const maxHour = agencyHours[agencyHours?.length -1]
                    let selectedHour = split[0]
                    if(selectedHour < minHour) {
                        selectedHour = minHour
                    } else if (selectedHour > maxHour) {
                        selectedHour = maxHour
                    }
                    setState({hour: selectedHour, minute: split[1]})
                } else {
                    setState({hour: split[0], minute: split[1]})
                }
            }
        }
    }, [value, agencyHours])

    useEffect(() => {
        if(day) {
            let hourList = []
            const date = parse(day, 'yyyy-MM-dd', new Date())
            if (isToday(date)) {
                const minHour = format(new Date(), 'HH')
                sandbox.range(0, parseInt(minHour)).forEach(nb => {
                    hourList = [...hourList, nb]
                })
            }
            if(withLimit) {
                if(min) {
                    const split = min?.split(':')
                    if(split?.length) {
                        sandbox.range(0, parseInt(split[0]) + hourDiff ).forEach(nb => {
                            if(!hourList?.includes(nb)) {
                                hourList = [...hourList, nb]
                            }
                        })
                    }
                }
                if(max) {
                    const split = max?.split(':')
                    if(split?.length) {
                        sandbox.range(parseInt(split[0]) - hourDiff, 25).forEach(nb => {
                            if(!hourList?.includes(nb)) {
                                hourList = [...hourList, nb]
                            }
                        })
                    }
                }
            }
            hourList = hourList.filter(h => agencyHours.includes(h))
            setDisabledHours(hourList)
        }
    }, [min,max,withLimit, day])

    useEffect(() => {
        if (agencyHours !== null){
            let newHoursRange = sandbox.range(0,24)
            if (agencyHours.length > 0){
                newHoursRange = newHoursRange.filter(h => agencyHours.includes(h))
            }
            setHoursRange(newHoursRange)
        }
    }, [agencyHours]);


    useEffect(() => {
        let ranges = [0]
        let matchWithAgencyHours = false
        
        if (hoursAndMinutes?.length > 0 ){
            hoursAndMinutes?.filter(h => h.type === "end")?.map(h => h.time)?.forEach((h) => {
                const hour = h.split(":")[0]
                const minute = h.split(":")[1]
                if (hour === state.hour) {
                    matchWithAgencyHours = true
                    if (minute == 15) {
                        ranges = [...ranges, 15]
                    }
                    if (minute == 30) {
                        ranges = [...ranges,15, 30]
                    }
                    if (minute == 45) {
                        ranges = [...ranges,15,30,45]
                    }
                    
                }
            })
        }

        if (hoursAndMinutes?.length > 0){
            hoursAndMinutes?.filter(h => h.type === "start")?.map(h => h.time)?.forEach((h) => {
                const hour = h.split(":")[0]
                const minute = h.split(":")[1]
                if (hour === state.hour) {
                    matchWithAgencyHours = true
                    ranges = []
                    if (minute == 0) {
                        ranges = [0]
                    }
                    if (minute == 15) {
                        ranges = [15, 30, 45]
                    }
                    if (minute == 30) {
                        ranges = [30, 45]
                    }
                    if (minute == 45) {
                        ranges = [45]
                    }
                }
            })

        }
        if (!matchWithAgencyHours){
            ranges = [0,15,30,45]
        }
        // remove duplicates from the ranges array
        ranges = [...new Set(ranges)]
        setMinutesRange(ranges)

    }, [state.hour])

    const handleBackButtonClick = () => {
        onBackButtonClick && onBackButtonClick()
    }

    const handleChange = ({name_input, value}) => {
        if(name_input === 'hour') {
            onChange && onChange({name, value: `${value}:${state.minute}`}, false)
        } else if (name_input === 'minutes') {
            onChange && onChange({name, value: `${state.hour}:${value}`})
        }
    }

    const handleMinuteItemClick = (value) => {
        if(value === state.minute) {
            onChange && onChange({name, value: `${state.hour}:${value}`})
        }
    }
    const handleHourItemClick = (value) => {
        if(value === state.hour) {
            onChange && onChange({name, value: `${value}:${state.minute}`}, false)
        }
    }

    const handleConfirmButtonCLick = () => {
        let selectedHour = state.hour
        const formattedHours = hoursRange?.map((nb) => sandbox.numberPad(nb, 2))
        if(!formattedHours.includes(state.hour)) {
            selectedHour = formattedHours[0]
        }
        onChange && onChange({name, value: `${selectedHour}:${state.minute}`})
    }

    return (
        <SlideAnimation className={"w-100 d-flex align-items-center"} style={{height: "300px"}}>
            <Row className={"w-100 h-100 align-items-center"}>
                <Col lg={6} className={cx("d-flex", {"justify-content-around": startDate == null}, {'justify-content-start': startDate})}>
                    <div className={"d-flex flex-column align-items-center w-100"}>
                        <div className={cx("w-100 d-flex" ,{"justify-content-around" : day === ""})}>
                            <p className={"rounded-circle c-main pointer bold  text-start p-3 border d-flex w20 h20 justify-content-center align-items-center"} onClick={handleBackButtonClick}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </p>
                            {!day && (<p className={"medium fs18 text-center"}>Veuillez indiquer le jour de {mode === "start" ? "début" : "retour"}</p>)}
                        </div>
                        {day && (
                            <>
                                <p className={"medium fs18"}>Date de {mode === "start" ? "début" : "retour"}</p>
                                <p className={"mt-3"}> {startDate != null && (format(startDate, 'EEEE dd MMMM yyyy', {locale: fr} ))}</p>
                                {agencyAvailabilityValues && (
                                    <div className={"mt-3"}>
                                        <p className={"medium text-center"}>Agence {agencyName}</p>
                                        {agencyAvailabilityValues?.length > 0 && <AgencyHours availabilities={agencyAvailabilityValues} />}
                                    </div>
                                )}

                                {agencyOperatingHours && (
                                    <div className={"mt-3"}>
                                        <p className={"medium text-center"}></p>
                                        {agencyOperatingHours?.length > 0 && <AgencyHours agencyOperatingHours={agencyOperatingHours} mode={mode} />}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Col>
                <Col lg={6} className={"h-75 border-start h-100 d-flex align-items-center"}>
                    {day && (
                        <div className={"d-flex flex-column w-100"}>
                            <div className={"d-flex justify-content-around w-100 align-items-center"}>
                                <FormControl
                                    className={"w30p bold text-center"}
                                >
                                        <Select
                                            value={state.hour}
                                            name={'hour'}
                                            native
                                            inputProps={{className: "text-center medium"}}
                                            onChange={(e) => handleChange({name_input: e.target.name,value: e.target.value})}
                                        >
                                            {hoursRange?.map((nb) => {
                                                const itemValue = sandbox.numberPad(nb, 2)
                                                return <option
                                                    key={`hour-option-${mode}-${nb}`}
                                                    value={itemValue}
                                                    disabled={disabledHours.includes(nb)}
                                                >
                                                    {itemValue}
                                                </option>
                                            })}
                                        </Select>
                                </FormControl>
                                <p className={"bold fs24"}>:</p>
                                <FormControl
                                    className={"w30p bold text-center"}
                                >
                                    <Select
                                        value={state.minute}
                                        name={'minutes'}
                                        native
                                        inputProps={{className: "text-center medium"}}
                                        onChange={(e) => handleChange({name_input: e.target.name,value: e.target.value})}
                                    >
                                        {minutesRange?.map((nb) => {
                                            const itemValue = sandbox.numberPad(nb, 2)
                                            return <option
                                                key={`minutes-option-${mode}-${nb}`}
                                                value={itemValue}
                                            >
                                                {itemValue}
                                            </option>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={"mt-5 d-flex justify-content-end"}>
                                <Button  className={""} onClick={handleConfirmButtonCLick}>Valider</Button>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </SlideAnimation>

    );
};

export default TimePicker;
